.home {
    --bd-purple: #4c0bce;
    --bd-violet: #712cf9;
    --bd-accent: #ffe484;
    --bd-violet-rgb: 112.520718,44.062154,249.437846;
    --bd-accent-rgb: 255,228,132;
    --bd-pink-rgb: 214,51,132;
    --ss-blue: #0e47a1;

    .bd-masthead {
        padding: 3rem 0;
        background: url('../img/wind_turbines_blue.jpg') center center repeat;
        background-size: cover;
        background-color: var(--ss-blue);
    }

    .btn-bd-primary {
        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-violet);
        --bs-btn-border-color: var(--bd-violet);
        --bs-btn-border-radius: .5rem;
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: #6528e0;
        --bs-btn-hover-border-color: #6528e0;
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: #5a23c8;
        --bs-btn-active-border-color: #5a23c8;
    }
}

.navbar {
    @extend .navbar-light;

    // --bd-violet-rgb: 112.520718,44.062154,249.437846;
    
    --ss-blue: #0e47a1;
    --ss-white: #ffffff;

    color: var(--ss-blue);
    padding: 0;
    background-color: var(--ss-white);
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15),inset 0 -1px 0 rgba(0,0,0,0.15);
    border-bottom: solid 5px #000;
}

.navbar-nav {
    // --bs-nav-link-color: #0e47a1;
    // --bs-nav-link-hover-color: #0a3375;
    // --bs-navbar-active-color: #0e47a1;
}

.navbar-brand {
    position:relative;
    bottom: -10px;
}

.text-violet {
    color: #6528e0;
}

.text-ss-blue {
    color: #0e47a1;
}

.list {
    .card {
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }
    }
}

.masthead-carousel {
    min-height: 320px;
}

.masthead-carousel .carousel-item {
    color: #fff;
    text-align: center;
}

.masthead-carousel .carousel-item {
    h1, h2, h3, h4 {
        margin-bottom: 1rem;
        letter-spacing: 0.20em;
        text-shadow: 2px 2px 1px #000;
        text-transform: uppercase;
    }
}

.masthead-carousel .carousel-item {
    h1 {
        margin-bottom: 3rem;
    }
}

.masthead-carousel .carousel-item {
    ul, p {
        font-size: 18px;
        font-weight: 700;
        padding: 0 1rem;
        text-transform: uppercase;
        letter-spacing: 0.10em;
        text-shadow: 2px 2px 1px #000;
        list-style-type: none;
    }
}

.masthead-img {
    height:80px;
    margin-left: 50%; 
    transform: translateX(-50%);
}

@media screen and (max-width: 480px) {
    
    .masthead-img {
        height:40px;
        margin-left: 50%; 
        transform: translateX(-50%);
    }

    .masthead-carousel .carousel-item {
        ul, p {
            font-size: 14px;
        }
    }

    .navbar {
        border-bottom: solid 3px #000;
    }

    .navbar-toggler {
        --bs-border-width: 0;
    }

    .navbar-brand img {
        max-height: 20px;
        position:relative;
        bottom: -6px;
    }
}

.feature-more {
    padding-left: 1rem;
    font-weight: bold;
    font-style: italic;
}